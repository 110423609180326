import { environment } from 'src/environments/environment';
import { HabitEntityState } from './habits.model';
import { HabitLogsEntityState } from './habit-logs.model';
import { HabitGroupsEntityState } from './habit-groups.model.';

export const APIUrls = {
  baseUrl: environment.apiURL,
  ai: 'ai/query',
  aiJSON: 'ai/queryJSON',
  aiTextToSpeech: 'ai/text-to-speech',
  habit: {
    getHabits: 'habits/get-habits',
    addHabit: 'habits/add-habit',
    updateHabit: 'habits/update-habit',
    deleteHabit: 'habits/delete-habit',
  },
  habitLogs: {
    getHabitLogs: 'habits-logs/get-habit-logs',
    addHabitLog: 'habits-logs/add-habit-log',
    updateHabitLog: 'habits-logs/update-habit-log',
    deleteHabitLog: 'habits-logs/delete-habit-log',
  },
  habitHistory: {
    getHabitHistory: 'habit-history/get-habit-history',
    addHabitHistory: 'habit-history/add-habit-history',
    updateHabitHistory: 'habit-history/update-habit-history',
    deleteHabitHistory: 'habit-history/delete-habit-history',
  },
  habitGroups: {
    getHabitGroups: 'habit-groups/get-habit-groups',
    createHabitGroup: 'habit-groups/add-habit-group',
    updateHabitGroup: 'habit-groups/update-habit-group',
    deleteHabitGroup: 'habit-groups/delete-habit-group',
  },
};

export enum HabitTrackerRoutes {
  root = '/habit-tracker',
  home = '/habit-tracker/home',
}

export const HabitStoreKeys = {
  habit: 'habit',
  habitLogs: 'habitLogs',
  habitHistory: 'habitHistory',
  habitGroups: 'habitGroups',
};

export interface HabitTrackerStoreState {
  habit: HabitEntityState;
  habitLogs: HabitLogsEntityState;
  habitHistory: HabitGroupsEntityState;
}

export type Quote = {
  quote: string;
  author: string;
};
